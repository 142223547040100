html{
  font-size: 16px;
  -webkit-touch-callout: none; /* iOS Safari */
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.example::-webkit-scrollbar {
  display: none; /* Chrome */
}
html, body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
html::-webkit-scrollbar {
  display: none;
}
@font-face {
  font-family: interMedium;
  src: url(../public/fonts/interFont/Inter-Medium.ttf);
}

@font-face {
  font-family: monoMedium;
  src: url(../public/fonts/Overpass_Mono/static/OverpassMono-Medium.ttf);
}


:root {
  --darkFont-color: #333333;
  --greyFont-color: #979797;
  --brand-red-color: #A0191E;
  --brand-yellow-color: #FFD099;
  --brand-orange-color: #FF8A00;
  --white-color: #FFFFFF;
  --grey-bg-color: #FDFDFD;
  --lightGreyTransparent-color: rgba(250,250,250,0.6);
  --signOutButton-color: #FF826D;
  --border-color: #E7E7E7;
  --patientSearchBG-color: #F3F2F3;
  --active-color: #45967B;
  --activeBG-color: #95E6CB;
  --suspended-color: #C08147;
  --suspendedBG-color: #FCBD83;
  --patientsViewButtonNotActive-color: #656565;
  --searchBarFocused-color: rgba(51,51,51,0.16);
  --searchBarNotFocused-color: #ECEBEC;
  --searchButtonDisabled-color: #E3E3E4;
  --unchecked-border-color: #C9C9C9;
  --cancelButtonHover-color: #E0E8E0;
  --deleteButton-color: #E6E6E6;
  --brand-primary-12-color: rgba(22, 1, 200, 0.12);
  --trendsTitle-color: #737476;
  --manual-color: #333333;
  --spinnerBG-color:#DFDEDF;
  --headerbackground-color: #F5F5F5;
  --transparentWhite80-color: rgba(255,255,255,0.8);
  --boxshadow-color: rgba(51,51,51,0.12);
  --black-bg-90-color: rgba(51,51,51,0.9);
  --modalBackground: rgba(51,51,51,0.6);
}

#groot {
  height:100vh;
  width:100vw;
  display: flex;
  position:relative;
}

.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.react-datepicker-popper{
  z-index: 2 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.greyBar{
  border: 0.0625rem solid var(--insulinMaxUnit-color);
}
